import { Component } from '@angular/core';
import { faDiscord, faFacebook, faGithub, faInstagram, faMastodon, faThreads, faTiktok, faTwitch, faTwitter, faWordpressSimple, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faCoffee, faRss } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MacSourcePorts';
  faGithub = faGithub;
  faTwitter = faTwitter;
  faInstagram = faInstagram
  faCoffee = faCoffee;
  faHeart = faHeart;
  faDiscord = faDiscord;
  faYoutube = faYoutube;
  faWordpress = faWordpressSimple;
  faTwitch = faTwitch;
  faRss = faRss;
  faMastodon = faMastodon;
  faTikTok = faTiktok;
  faThreads = faThreads;
  faFacebook = faFacebook;
  collapsed = true;
  currentYear = new Date().getFullYear();
}
